import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    selectedLead: null,
    selectedQuery: null,
    selectedResponse: null,
    activeChatbox: null,
    messageId: null,
    selectedSynonyms: [],
    sync: false
};

export const trainingInterfaceSlice = createSlice({
    name: "trainingInterface",
    initialState,
    reducers: {
        setChatboxDetails(state, action) {
            const { index, userMessage, botMessage, chatbotMessageId, leadId } = action.payload;
            state.activeChatbox = index;
            state.selectedQuery = userMessage;
            state.selectedLead = leadId;
            state.messageId = chatbotMessageId;
            state.selectedResponse = botMessage;
          },
        setRerunResponse(state,action){
            state.selectedResponse = action.payload
        },
        setSelectedSynonyms(state,action){
            state.selectedSynonyms = action.payload
        },
        setSync(state, action) {
            state.sync = action.payload;
        },
        resetState(state) {
            return initialState;
          },
    },

})

export const { resetState, setChatboxDetails, setRerunResponse, setSync, setSelectedSynonyms } = trainingInterfaceSlice.actions;
export default trainingInterfaceSlice.reducer;